
import $ from 'jquery';
import slick from 'vendor/jquery/slick-carousel';

const $window = $(window);

export const initInstance = function(el) {

	const $slider = $(el);

	$slider.slick({
		variableWidth: false,
		centerMode: false,
		dots: true,
		infinite: false,
	});
}
